import React from "react";
import Proje1 from "../assets/proje1-mobilapp.jpg";
import Proje2 from "../assets/proje2-derin.png";
import Proje3 from "../assets/proje3-img2.png";
import Proje4 from "../assets/proje4-trdyol.png";

const projectData = [
  {
    baslik:
      "Node.js + Flutter İş-Görev ve Basit Muhasebe Takibi Mobil Uygulaması",
    aciklama:
      "Dönem projem olan Flutter ile geliştirilmiş freelancer yazılımcıları hedef alan iş-görev ve basit muhasebe takibi mobil uygulaması. Teknolojiler: Node.js, MongoDB, Figma, Flutter",
    resim: Proje1,
    url: "https://github.com/alihandemirdas/Proje-Mobil-Uygulama-NodeJS-Flutter",
  },
  {
    baslik: "Derin Öğrenme ile Bitki Hastalıklarının Tespiti ve Web Uygulaması",
    aciklama:
      "Bitirme projesi olarak Derin Öğrenme teknikleri kullanılarak yaprak fotoğraflarından bitki hastalıklarının analizi ve bunların bir React uygulaması ile son kullanıcıya sunumu gerçekleştirildi.",
    resim: Proje2,
    url: "#portfolio",
  },
  {
    baslik: "Resim Dönüştürme (Image Converter) Platformu",
    aciklama:
      "Node.js, React ve Tailwind kullanarak geliştirdiğim online resim dönüştürme -image converter- platformudur. Kullanıcı yüklediği fotoğrafları (şimdilik) PNG, JPG ve HEIC formatlarına kalite kaybı olmaksızın dönüştürebilir.",
    resim: Proje3,
    url: "https://img2.org",
  },
  {
    baslik: "Trendyol Ürün Fiyat Takip Botu",
    aciklama:
      "Bu bot ile Trendyol'daki fiyatlarını takip etmek istediğiniz ürünleri, linklerini programa ekleyerek toplu bir şekilde istediğiniz aralıklarda kontrol edebilirsiniz. Eğer ürünün fiyatı düşerse, Telegram üzerinden oluşturduğunuz gruba anlık olarak bildirim gönderir.",
    resim: Proje4,
    url: "https://github.com/alihandemirdas/Trendyol-Urun-Fiyat-Takip-Bot",
  },
];

const Portfolio = () => {
  return (
    <div id="portfolyo" className="grid pb-20 gap-x-12 gap-y-6 my-5">
      <div className="flex flex-col justify-center items-center">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 576 512"
          width="40"
          height="40"
          className="mb-4"
        >
          <path
            className="dark:fill-white"
            d="M64 0C28.7 0 0 28.7 0 64V352c0 35.3 28.7 64 64 64H240l-10.7 32H160c-17.7 0-32 14.3-32 32s14.3 32 32 32H416c17.7 0 32-14.3 32-32s-14.3-32-32-32H346.7L336 416H512c35.3 0 64-28.7 64-64V64c0-35.3-28.7-64-64-64H64zM512 64V288H64V64H512z"
          />
        </svg>
        <h1 className="text-4xl font-bold mb-4 text-center dark:text-white">
          Portfolyo
        </h1>
        <p className="text-xl font-light text-center dark:text-gray-50">
          Üniversite döneminde aldığım derslerde ve kişisel olarak geliştirdiğim
          projeler
        </p>
        <p className="text-base font-normal text-center bg-gradient-to-r from-[#ED1944] to-[#EE8621] bg-clip-text text-transparent">
          {"<>"} Bu websitesi tarafımca Node.js, React ve TailwindCSS
          kullanılarak geliştirilmiştir. {"</>"}
        </p>
        <div class="inline-flex items-center justify-center w-full">
          <hr class="w-full h-1 my-8 bg-gradient-to-r from-[#ED1944] via-fuchsia-500 to-[#EF8D2D] border-0 rounded-3xl" />
        </div>
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 xl:grid-cols-4 gap-x-6 gap-y-6">
        {projectData.map((data, index) => (
          <div className="max-w-xl flex flex-col bg-gradient-to-t from-gray-50 to-white border border-gray-200 rounded-lg shadow">
            <div className="">
              <img
                className="rounded-t-lg w-full h-[150px] lg:h-[200px]"
                src={data.resim}
                alt=""
              />
              <div className="flex flex-col flex-1 p-6">
                <h5 className="mb-2 text-2xl font-semibold tracking-tight text-gray-900">
                  {data.baslik}
                </h5>
                <p className="mb-3 font-light text-gray-700 flex-1">
                  {data.aciklama}
                </p>
                <button
                  className="mt-auto mx-10 flex items-center justify-center gap-x-2 font-medium text-center transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-sm py-3 px-6 bg-gradient-to-tr from-[#ED1944] to-[#EE8621] text-white shadow-md shadow-gray-900/10 hover:shadow-lg hover:shadow-gray-900/20 active:opacity-[0.85] rounded-full"
                  type="button"
                  onClick={() => (window.location.href = data.url)}
                >
                  Daha Fazla
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                    className="w-4 h-4"
                    fill="#fff"
                  >
                    <path d="M502.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L402.7 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l370.7 0-73.4 73.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l128-128z" />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Portfolio;
