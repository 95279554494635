import React from "react";
import Logo3D from "../assets/logo-main.png";
import { motion } from "framer-motion";

const About = () => {
  return (
    <div
      id="hakkimda"
      className="grid xl:grid-cols-3 pb-10 gap-x-12 gap-y-6 my-5"
    >
      <div className="flex col-span-1 justify-center items-center w-full">
        <motion.img
          src={Logo3D}
          alt="Alihan Demirdaş Hakkımda Görseli"
          className="rounded-full shadow-2xl"
          initial={{ scale: 1, opacity: 1, rotate: 0 }}
          animate={{
            scale: [1, 1.1, 1], // Zıplama efekti
            rotate: [0, 13, -12, 0], // Döndürme efekti
            opacity: [1, 0.9, 1], // Opaklık değişimi
            x: [0, 10, -10, 0], // Yatay hareket efekti
            y: [0, -10, 10, 0], // Düşey hareket efekti
          }}
          transition={{
            duration: 4, // Animasyon süresi
            repeat: Infinity, // Sonsuz tekrar
            repeatType: "loop", // Tekrar türü
            ease: "easeInOut", // Geçiş yumuşaklığı
            times: [0, 0.5, 1], // Zamanlama noktaları
          }}
        />
      </div>
      <div className="flex flex-col col-span-2 justify-center">
        <h1 className="text-4xl font-bold mb-4 bg-gradient-to-r from-[#ED1944] via-fuchsia-500 to-[#EF8D2D] bg-[length:100%_5px] bg-no-repeat bg-bottom dark:text-white">
          Hakkımda
        </h1>
        <p className="text-lg text-gray-700 dark:text-white">
          Merhaba! Ben Alihan. Necmettin Erbakan Üniversitesi Bilgisayar
          Mühendisliği mezunuyum. Full-Stack Web Geliştirme ve Mobil Uygulama
          Geliştirme alanlarında çalışmalar yapıyorum. Yapay Zeka ve Derin
          Öğrenme konularına meraklıyım ve ilgileniyorum. Algoritmalar
          geliştirmek, problemleri çözmek ve sistemler tasarlamak bana
          mühendisliği gerçekten hissettiren şeyler ve bu yönde çalışmalarımı
          gerçekleştiriyorum. Dijital pazarlama ve reklamcılık hobilerimdendir.
        </p>
      </div>
    </div>
  );
};

export default About;
