import React, { useEffect, useState } from "react";
import Logo3D from "../assets/logo-main.png";

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isDarkMode, setIsDarkMode] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  const toggleDarkMode = () => {
    setIsDarkMode(!isDarkMode);
    if (!isDarkMode) {
      document.documentElement.classList.add("dark");
    } else {
      document.documentElement.classList.remove("dark");
    }
  };

  useEffect(() => {
    const savedTheme = localStorage.getItem("theme");
    if (savedTheme === "dark") {
      setIsDarkMode(true);
      document.documentElement.classList.add("dark");
    }
  }, []);

  // Save theme preference
  useEffect(() => {
    localStorage.setItem("theme", isDarkMode ? "dark" : "light");
  }, [isDarkMode]);

  return (
    <div className="flex flex-wrap items-center justify-between py-2 px-12">
      <a
        href="https://alihandemirdas.com.tr"
        className="flex items-center space-x-3 rtl:space-x-reverse"
      >
        <img src={Logo3D} className="h-20 w-20" alt="Alihan Demirdaş Logo" />
      </a>
      <button
        onClick={toggleMenu}
        type="button"
        className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg lg:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
        aria-controls="navbar-default"
        aria-expanded={isMenuOpen}
      >
        <span className="sr-only">Open main menu</span>
        <svg
          className="w-5 h-5"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 17 14"
        >
          <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M1 1h15M1 7h15M1 13h15"
          />
        </svg>
      </button>
      <div
        className={`${
          isMenuOpen ? "block" : "hidden"
        } w-full lg:block lg:w-auto`}
        id="navbar-default"
      >
        <ul className="font-medium flex flex-col p-4 lg:p-0 mt-4 lg:flex-row lg:space-x-8 rtl:space-x-reverse lg:mt-0 lg:justify-center lg:items-center">
          <li>
            <a
              href="https://alihandemirdas.com.tr/"
              className="block py-2 px-3 text-white bg-blue-700 rounded lg:bg-transparent lg:text-blue-700 lg:p-0 dark:text-white lg:dark:text-blue-500"
              aria-current="page"
              onClick={closeMenu} // Close menu on click
            >
              Anasayfa
            </a>
          </li>
          <li>
            <a
              href="https://alihandemirdas.com.tr/#hakkimda"
              className="block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 lg:hover:bg-transparent lg:border-0 lg:hover:text-blue-700 lg:p-0 dark:text-white lg:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent"
              onClick={closeMenu} // Close menu on click
            >
              Hakkımda
            </a>
          </li>
          <li>
            <a
              href="https://alihandemirdas.com.tr/#deneyim"
              className="block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 lg:hover:bg-transparent lg:border-0 lg:hover:text-blue-700 lg:p-0 dark:text-white lg:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent"
              onClick={closeMenu} // Close menu on click
            >
              Deneyim
            </a>
          </li>
          <li>
            <a
              href="https://alihandemirdas.com.tr/#portfolyo"
              className="block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 lg:hover:bg-transparent lg:border-0 lg:hover:text-blue-700 lg:p-0 dark:text-white lg:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent"
              onClick={closeMenu} // Close menu on click
            >
              Portfolyo
            </a>
          </li>
          <li>
            <a
              href="https://alihandemirdas.com.tr/#iletisim"
              className="block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 lg:hover:bg-transparent lg:border-0 lg:hover:text-blue-700 lg:p-0 dark:text-white lg:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent"
              onClick={closeMenu} // Close menu on click
            >
              İletişim
            </a>
          </li>
          {/* Dark Mode Toggle Button */}
          <li>
            <button
              onClick={toggleDarkMode}
              className="block py-1 px-4 text-gray-900 dark:text-white dark:bg-gray-700 hover:bg-gray-300 dark:hover:bg-gray-600 rounded-xl bg-gray-200"
            >
              {isDarkMode ? "☀️" : "🌙"}
            </button>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Navbar;
